<template>

	<div style="padding: 20px;">
		<div style="display: flex; margin-bottom: 20px;">
			<el-card class="home-card2" style="margin-bottom: 0;" v-loading="loading_3">
				<div class="home-card-title">{{$t('i18nn_a967bc023b5157c4')}}</div>
				<div class="home-card-item-top">
					<div class="home-card-item home-card-item-s5 card-active-hover"
						@click="toPageName($event, 'OrderStatisticsByMon')">
						<div class="home-card-name">{{$t('684a2afb069b6016')}}</div>
						<div class="home-card-val" v-if="OrderStatisticsData.SDS">{{ OrderStatisticsData.SDS.totalCount }}</div>
					</div>
				</div>
				<div class="home-card-item-top">
					<div class="home-card-item home-card-item-s4 card-active-hover"
						@click="toPageName($event, 'OrderStatisticsByMon')">
						<div class="home-card-name">{{$t('i18nn_6373cb1d1204d580')}}</div>
						<div class="home-card-val" v-if="OrderStatisticsData.STF">{{ OrderStatisticsData.STF.totalCount }}</div>
					</div>
				</div>
			</el-card>
			<!-- 订单 -->
			<div style="flex:1">
				<OrderStatistics></OrderStatistics>
			</div>
		</div>
		
		
		<!-- 账单 -->
		<BillStatistics></BillStatistics>
		<!-- 货量 -->
		<CargoVolumeStatistics></CargoVolumeStatistics>

	</div>


</template>

<script>
	
	import OrderStatistics from '@/components/WarehouseCenter2/Home/components/OrderStatistics.vue';
	import BillStatistics from '@/components/WarehouseCenter2/Home/components/BillStatistics.vue';
	import CargoVolumeStatistics from '@/components/WarehouseCenter2/Home/components/CargoVolumeStatistics.vue';
	export default {
		components: {
			OrderStatistics,
			BillStatistics,
			CargoVolumeStatistics
		},
		data() {
			return {

				loading_3: false,
				OrderStatisticsData: {},
			};
		},
		activated() {
			this.initData();
		},
		mounted() {},
		methods: {
			initData() {
				this.getOrderStatisticsData();
				// this.getCargoVolumeData();
			},
			toPageName(event, name, query) {
				event.stopPropagation();
				this.$router.push({
					name: name,
					query: query
				});
			},
			// 订单统计
			getOrderStatisticsData() {
				this.loading_3 = true;
				this.$http
					.put(this.$urlConfig.WhMonthOrderTotal, {})
					.then(({
						data
					}) => {
						this.loading_3 = false;
						if (200 == data.code && data.data) {
							this.OrderStatisticsData = data.data;
						} else {
							// this.$message.warning('订单统计-出库情况数据,请求失败');
						}
					})
					.catch(error => {
						console.log(error);
						this.loading_3 = false;
						// this.$message.warning('订单统计-出库情况数据,请求失败！');
					});
			},
			// 仓库货量
			// getCargoVolumeData() {
			// 	// this.loading_3 = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhSkuDayStockCurrentVolume, {"startTime":"2023-03-15"})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// this.loading_3 = false;
			// 			if (200 == data.code && data.data) {
			// 				// this.OrderStatisticsData = data.data;
			// 			} else {
			// 				this.$message.warning('仓库货量数据,请求失败');
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			// this.loading_3 = false;
			// 			this.$message.warning('仓库货量数据,请求失败！');
			// 		});
			// },
		}
	};
</script>

<style scoped lang="less">
	@import url(../../../assets/css/home.less);
</style>
