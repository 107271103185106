<template>
	<div style="display: flex; margin: 20px 0;">
		<el-card class="home-card2" style="margin-bottom: 0;" v-loading="loading_load_all">
			<div class="home-card-title">{{$t('i18nn_87f891127ffff1f8')}}<br />({{dateStr}})</div>
			<div class="home-card-item-top">
				<div class="home-card-item home-card-item-s5 card-active-hover" @click="toMoreList()">
					<div class="home-card-name"><span>{{$t('hytxs0000010')}}</span>(m³)</div>
					<div class="home-card-val">{{ totalVol }}</div>
				</div>
			</div>
			<div class="home-card-item-top">
				<div class="home-card-item home-card-item-s4 card-active-hover" @click="toMoreList()">
					<div class="home-card-name"><span>{{$t('hytxs0000009')}}</span>(kg)</div>
					<div class="home-card-val">{{ totalWeight }}</div>
				</div>
			</div>
		</el-card>
		<div style="flex:1">
			<el-card>
				<div slot="header" style="display: flex;justify-content: space-between;">
					<div style="font-size: 16px;">
						<i class="el-icon-data-line"></i>
						<span>{{$t('i18nn_051e757f5c7e8026')}}</span>
						<el-tag type="success">{{dateStr}}</el-tag>
						<!-- <el-tag type="primary" v-if="FeeAllTotal">总金额：{{FeeAllTotal}}</el-tag> -->
						<el-button-group>
							<el-button size="small" :type="0==CarouselIndex?'primary':''" @click="setActiveItem(0)">{{$t('i18nn_f340f0ba85ea230d')}}</el-button>
							<el-button size="small" :type="1==CarouselIndex?'primary':''" @click="setActiveItem(1)">{{$t('96f1a4b17e75892d')}}</el-button>
						</el-button-group>
					</div>
					<div>
						<el-link type="primary" @click="toMoreList()">
							<span>{{$t('i18nn_c3525eb110db58aa')}}</span>...
						</el-link>
					</div>
				</div>
				<div style="width: 100%;">

					<!-- <el-tabs v-model="activeName" style="width: 100%;">
					<el-tab-pane :label="$t('i18nn_428b0a18700aa88e')" name="first" style="width: 100%;">
						<div v-loading="loading_load_all" style="width: 100%;">
							<ChartsSmallBillTotal :openTime="ChartsAllDataOpenTime" :chartData="ChartDataAll">
							</ChartsSmallBillTotal>
						</div>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_0d35fa57b3aab0f7')" name="second" style="width: 100%;">
						<div v-loading="loading_cus" style="width: 100%;">
							<ChartsSmallBillTotal :openTime="ChartsDayDataOpenTime" :chartData="ChartDataDay">
							</ChartsSmallBillTotal>
						</div>
					</el-tab-pane>
				</el-tabs> -->

					<el-carousel height="220px" ref="carousel" :autoplay="true" :interval="10000" direction="vertical"
						@change="changeCarousel">
						<el-carousel-item>
							<div v-loading="loading_load_all">
								<ChartsSmallBillTotal :openTime="ChartsAllDataOpenTime" :chartData="ChartDataAll">
								</ChartsSmallBillTotal>
							</div>
						</el-carousel-item>
						<el-carousel-item>
							<div v-loading="loading_load_all">
								<ChartsSmallBillTotal :openTime="ChartsDayDataOpenTime" :chartData="ChartDataDay">
								</ChartsSmallBillTotal>
							</div>
						</el-carousel-item>
					</el-carousel>
					<!-- <div style="display: flex;justify-content: space-between;">
					<el-card style="flex:1;">
						<div slot="header">
							<div style="font-size: 16px;">
								<i class="el-icon-data-line"></i>
								<span>{{$t('i18nn_48c855b33061cbb9')}}</span>
								<el-tag type="primary" v-if="FeeAllTotal">总金额：{{FeeAllTotal}}</el-tag>
							</div>
						</div>
						<div v-loading="loading_load_all">
							<ChartsSmallBillTotal :openTime="ChartsAllDataOpenTime" :chartData="ChartDataAll">
							</ChartsSmallBillTotal>
						</div>
					</el-card>
					<el-card style="flex:1;">
						<div slot="header">
							<div style="font-size: 16px;">
								<i class="el-icon-data-line"></i>
								<span>{{$t('i18nn_d099c84ccfd8a4bb')}}</span>
							</div>
						</div>
						<div v-loading="loading_load_all">
							<ChartsSmallBillTotal :openTime="ChartsDayDataOpenTime" :chartData="ChartDataDay">
							</ChartsSmallBillTotal>
						</div>
					</el-card>
				</div> -->

				</div>
			</el-card>
		</div>
	</div>


</template>

<script>
	import ChartsSmallBillTotal from '@/components/WarehouseCenter2/Home/components/ChartsSmallBillTotal.vue';
	export default {
		components: {
			ChartsSmallBillTotal
		},
		data() {
			return {
				dateStr: "",
				loading_load: false,
				// loading_3:false,

				loading_load_all: false,
				loading_cus: false,

				CarouselIndex: 0,

				activeName: 'first',


				totalVol: '',
				totalWeight: '',

				ChartsAllDataOpenTime: '',
				// ChartsAllDataParm: {},
				ChartDataAll: {},


				ChartsDayDataOpenTime: '',
				// ChartsDayDataParm: {},
				ChartDataDay: {},
				//查询，排序方式
				// filterData: {
				// 	orderBy: 'id_', //排序字段
				// 	sortAsc: 'desc', //desc降序，asc升序

				// 	// agentUser: '',
				// 	// "accountPeriod":"",
				// 	daterange: [],
				// 	// goodsSku: '',
				// 	// whNo: '',
				// 	// userId: ''
				// 	// goodsSku: '',
				// 	// goodsName:""
				// }
			};
		},
		activated() {
			this.initData();
		},
		mounted() {},
		methods: {
			initData() {
				this.getAllFeeCountData();
				// this.getAllFeeCusData();
			},

			changeCarousel(index) {
				console.log('changeCarousel', index);
				this.CarouselIndex = index;
			},
			// toPageName(event, name, query) {
			// 	event.stopPropagation();
			// 	this.$router.push({
			// 		name: name,
			// 		query: query
			// 	});
			// },
			toMoreList() {
				// event.stopPropagation();
				this.$router.push({
					name: "CargoVolumeStatisticsByDay"
				})
			},

			// initChar(data) {
			// 	// this.ChartsMonDataParm = {};

			// 	// let XData = [],
			// 	// 	YData1 = [],
			// 	// 	YData2 = [];
			// 	// data.forEach(item => {
			// 	// 	XData.push(item.commitDate);
			// 	// 	YData1.push(item.totalCount);
			// 	// });
			// 	// data2.forEach(item => {
			// 	// 	YData2.push(item.totalCount);
			// 	// });
			// 	this.ChartTotalData = {
			// 		// title: this.$t('i18nn_52b4f481aab3e343'),
			// 		// type: '1',
			// 		Data:data
			// 	}
			// 	this.ChartsTotalOpenTime = new Date().getTime();
			// },

			//总数据
			initChar(data) {
				// this.ChartsAllDataParm = {};
				let XData = [],
					YData1 = [];
				data.forEach(item => {
					XData.push(item.whNo);
					YData1.push(item.totalVol);
				});
				// data2.forEach(item => {
				// 	YData2.push(item.totalCount);
				// });
				this.ChartDataAll = {
					title: this.$t('i18nn_f340f0ba85ea230d'),
					// type: '1',
					XData: XData,
					YData1: YData1,
				}
				this.ChartsAllDataOpenTime = new Date().getTime();
			},

			initChar2(data) {
				// this.ChartsDayDataParm = {};
				let XData = [],
					YData1 = [];
				data.forEach(item => {
					XData.push(item.whNo);
					YData1.push(item.totalWeight);
				});
				// data2.forEach(item => {
				// 	YData2.push(item.dayCount);
				// });
				this.ChartDataDay = {
					title: this.$t('96f1a4b17e75892d'),
					XData: XData,
					YData1: YData1,
					// YData2: YData2
				}
				this.ChartsDayDataOpenTime = new Date().getTime();
			},

			setActiveItem(index) {
				this.$nextTick(() => {
					this.$refs.carousel.setActiveItem(index);
				})
			},

			//分页的筛选项数据
			// pageFilterData() {
			// 	console.log('daterange', this.filterData.daterange);
			// 	let startTime = '';
			// 	let endTime = '';
			// 	if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
			// 		startTime = this.filterData.daterange[0];
			// 		endTime = this.filterData.daterange[1];
			// 	} else {
			// 		// this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
			// 	}
			// 	// startD endD
			// 	return {
			// 		// userId: this.filterData.userId ? this.filterData.userId : null,
			// 		// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
			// 		startD: startTime ? startTime : null,
			// 		endD: endTime ? endTime : null,
			// 		// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
			// 		// whNo: this.filterData.whNo ? this.filterData.whNo : null
			// 	};
			// },
			//所有费用汇总统计
			getAllFeeCountData() {
				//前30天
				// let filterData = {
				// 	opTimeStart: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
				// 	opTimeEnd: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime()),
				// };
				this.dateStr = this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime())
				this.loading_load_all = true;
				this.$http
					.put(this.$urlConfig.WhSkuDayStockCurrentVolume, {
						"startTime": this.dateStr
					})
					.then(({
						data
					}) => {
						this.loading_load_all = false;
						if (200 == data.code && data.rows) {
							let allData = data.rows;
							let totalVol = 0;
							let totalWeight = 0;
							allData.forEach(item => {
								totalVol = totalVol + parseFloat(item.totalVol);
								totalWeight = totalWeight + parseFloat(item.totalWeight);
							})
							this.totalVol = totalVol.toFixed(2);
							this.totalWeight = totalWeight.toFixed(2);

							let charData = allData;
							this.initChar(charData);
							this.initChar2(charData);
							// if (data.data.feeRecrods && data.data.feeRecrods.length > 0) {
							// 	let charData = data.data.feeRecrods;
							// 	// this.initChar(charData);
							// }
						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('i18nn_802437747d78fc60'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('i18nn_802437747d78fc60'));
						this.loading_load_all = false;
					});
			},

			//按客户费用汇总统计
			// getAllFeeCusData() {
			// 	//前30天
			// 	let filterData = {
			// 		opTimeStart: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
			// 		opTimeEnd: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime()),
			// 	};
			// 	this.loading_cus = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhBillAllFeeAndCus, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			this.loading_cus = false;
			// 			if (200 == data.code && data.rows) {
			// 				// this.tableDataCus = data.rows;
			// 				let charData = data.rows;
			// 				this.initChar2(charData);
			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('i18nn_0c72f3afd482899d'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error('按客户汇总数据查询失败！');
			// 			this.loading_cus = false;
			// 		});
			// },

			//请求分页数据
			// getPageData2() {
			// 	// let _this = this;

			// 	// let filterData = Object.assign({
			// 	// 	offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
			// 	// 	limit: this.pagination.page_size, //当前页显示数目
			// 	// },this.pageFilterData())

			// 	// let filterData = this.pageFilterData();

			// 	//前30天
			// 	let filterData = {
			// 		endD: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime()),
			// 		startD: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
			// 	};

			// 	//显示选择数据最后月的当月数据(1号至当月最后一号)
			// 	// let titleMon = "";
			// 	//点击的值
			// 	// if (dateMon) {
			// 	// 	filterData.endD = dateMon;
			// 	// }
			// 	//筛选传入的，最后月的值
			// 	// if (filterData.endD) {
			// 	// 	try {
			// 	// 		let newDataSec = this.$Utils.getMonByDaySection(filterData.endD);
			// 	// 		console.log("newDataSec", newDataSec);
			// 	// 		filterData.startD = newDataSec[0] ? newDataSec[0] : null;
			// 	// 		filterData.endD = newDataSec[1] ? newDataSec[1] : null;
			// 	// 		titleMon = "(" + newDataSec[2] + ")";
			// 	// 	} catch (e) {
			// 	// 		console.log(e)
			// 	// 		//TODO handle the exception
			// 	// 	}
			// 	// }

			// 	this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhOrderStatisticsByDay, filterData)
			// 		.then(({
			// 			data
			// 		}) => {
			// 			
			// 			this.loading_load = false;
			// 			if (200 == data.code && data.data) {
			// 				//表格显示数据
			// 				// this.tableData = data.data;
			// 				let charData = data.data;
			// 				charData.SDS = charData.SDS ? charData.SDS : [];
			// 				charData.STF = charData.STF ? charData.STF : [];

			// 				this.initChar2(charData.SDS, charData.STF, '');

			// 			} else {
			// 				this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			this.$message.error(this.$t('hytxs0000030'));
			// 			this.loading_load = false;
			// 		});
			// },
		}
	};
</script>

<style scoped lang="less">
	@import url(../../../../assets/css/home.less);
</style>
