<template>
	<!-- 导入excel -->
	<!-- <el-dialog :title="'SKU库存趋势统计'" append-to-body :close-on-click-modal="false" :visible.sync="dialogShow" width="1100px" top="0"> -->
	<!-- <div style="margin-top: 10px;" v-loading="loading"> -->
	<!-- <el-card> -->
	<!-- <div slot="header" class="">
          <h3>
            <i class="el-icon-notebook-2"></i>
            <span>SKU库存趋势统计</span>
          </h3>
        </div> -->
	<div ref="chartsMain" style="width: 100%; height: 200px;"></div>
	<!-- </el-card> -->
	<!-- </div> -->

	<!-- <div slot="footer" class="dialog-footer"> -->
	<!-- <el-button type="primary" plain @click="dialogShow = false">{{ $t('4e9fc68608c60999') }}</el-button> -->
	<!-- <el-button type="primary" @click="printerAction2()">{{$t('i18nn_886072e393710563')}}</el-button> -->
	<!-- </div> -->
	<!-- </el-dialog> -->
</template>
<script>
	// import * as echarts from 'echarts';
	// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
	import * as echarts from 'echarts/core';
	// 引入柱状图图表，图表后缀都为 Chart
	import {
		LineChart
	} from 'echarts/charts';
	// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
	import {
		TitleComponent,
		TooltipComponent,
		GridComponent,
		DatasetComponent,
		DatasetComponentOption,
		TransformComponent,
		ToolboxComponent,
		LegendComponent
	} from 'echarts/components';
	// 标签自动布局，全局过渡动画等特性
	import {
		LabelLayout,
		UniversalTransition
	} from 'echarts/features';
	// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
	import {
		CanvasRenderer
	} from 'echarts/renderers';

	// 注册必须的组件
	echarts.use([
		TitleComponent,
		TooltipComponent,
		GridComponent,
		DatasetComponent,
		TransformComponent,
		LineChart,
		LabelLayout,
		UniversalTransition,
		CanvasRenderer,
		ToolboxComponent,
		LegendComponent
	]);

	export default {
		// props: ['isDialog'],
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			// parm: {
			// 	default: function() {
			// 		return {
			// 			userId: '', //"用户ID",
			// 			goodsSKu: '', //this.$t('i18nn_74ed28f373ea14da'),
			// 			whNo: '' //this.$t('5acbec83efb27445')
			// 		};
			// 	},
			// 	type: Object
			// },
			chartData: {
				default: function() {
					return {
						title: '',
						x_name: '',
						y_name: '',
						XData: [],
						YData: [],
						// YData2: []
					};
				},
				type: Object
			}
			// fileList: {
			//   default: function() {
			//     return [];
			//   },
			//   type: Array
			// }
		},
		components: {},
		data() {
			return {
				// dialogShow: false,
				loading: false
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				//初始化
				// this.dialogShow = true;
				this.initData();
			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.initData();
		},
		methods: {
			initData() {
				this.$nextTick(() => {
					console.log('chartData', this.chartData);
					this.initCharts(this.chartData.title, this.chartData.x_name, this.chartData.y_name, this
						.chartData.XData, this.chartData.YData);
				});
				// this.getDetData();
			},

			initCharts(title, x_name, y_name, XData, YData) {
				// console.log('initCharts', XData, YData1);
				// 基于准备好的dom，初始化echarts实例
				var myChart = echarts.init(this.$refs.chartsMain, null, {
					renderer: 'svg'
				});
				// var myChart = echarts.init(document.getElementById('chartsMain'));
				myChart.clear();
				myChart.resize();
				let option = {
					title: {
						text: title
					},
					tooltip: {
						trigger: 'axis',
						// axisPointer: {
						// 	// Use axis to trigger tooltip
						// 	type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
						// }
					},
					// toolbox: {
					// 	feature: {
					// 		// dataZoom: {
					// 		// 	yAxisIndex: 'none'
					// 		// },
					// 		// dataView: { readOnly: false },
					// 		// magicType: { type: ['line', 'bar'] },
					// 		// restore: {},
					// 		saveAsImage: {}
					// 	}
					// },
					// legend: {
					// 	data: [y_name]
					// },
					grid: {
						left: '3%',
						right: '5%',
						top: '15%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						name: x_name,
						type: 'category',
						boundaryGap: false,
						//    boundaryGap: false,
						axisLabel: {
							interval: 'auto',
							rotate: '20'
						},
						// formatter: function (value, index) {
						// 	if(value.length>=10){
						// 		return value.substring(5,10);
						// 	} else {
						// 		return value
						// 	}
						// 	// return '111';
						// },
						data: XData
					},
					yAxis: {
						type: 'value',
						// triggerEvent: true
						// boundaryGap: [0, '10%']
					},
					series: [{
						name: y_name,
						type: 'line',
						smooth: true,
						label: {
							show: true,
							color: "#e6a23c",
							fontSize: 18,
							// backgroundColor: "rgba(192, 223, 248, 1)",
							// borderColor: "rgba(0, 64, 255, 1)",
							// borderWidth: 1,
							// padding: [5, 10, 5, 10],
							position: 'left',
							formatter: (params) => {
							    if (option.series[0].data.length - 1 == params.dataIndex) {
							        console.log(params)
							        console.log(params.value)
							        return params.value
							    } else {
							        return ""
							    }
							},
						},
						data: YData
					}]
				};
				//按月统计的
				// if ('1' == this.chartData.type) {
				// 	// 一件代发的
				// 	option.series[0].label.backgroundColor = 'rgba(192, 223, 248, 1)';
				// 	option.series[0].label.padding = [5, 10, 5, 10];
				// 	option.series[1].label.padding = [0, 10, 0, 10];
				// }
				if(this.$t('684a2afb069b6016')==y_name){
					option.color = ['#409eff']
				} else {
					option.color = ['#67c23a']
				}
				// 绘制图表
				myChart.setOption(option);

				// //按月统计的
				// if ('1' == this.chartData.type) {
				// 	myChart.on('click', param => {
				// 		console.log('click', param, param.data); //这里根据param填写你的跳转逻辑
				// 		// if (0 === param.componentIndex) {
				// 		//按月统计
				// 		this.$emit('clickMon', param.name);
				// 		// }
				// 	});
				// }
			}
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
