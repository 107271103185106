<template>
	<el-card>
		<div slot="header" style="display: flex;justify-content: space-between;">
			<div style="font-size: 16px;">
				<i class="el-icon-data-line"></i>
				<span>{{$t('i18nn_37181c74559db182')}}</span>
				<!-- <el-tag type="primary" v-if="0==CarouselIndex">月(前12个月)</el-tag>
				<el-tag type="success" v-else-if="1==CarouselIndex">日(前30天)</el-tag> -->
				<el-button-group>
				  <el-button size="small" :type="0==CarouselIndex?'primary':''" @click="setActiveItem(0)">
						<span>{{$t('i18nn_9954ce6437e66f41')}}</span>(<span>{{$t('i18nn_5734a059a4622f20')}}</span>12<span>{{$t('i18nn_bb3d670df5390f32')}}</span>)
					</el-button>
				  <el-button size="small" :type="1==CarouselIndex?'primary':''" @click="setActiveItem(1)">
						<span>{{$t('i18nn_ac2c5e612fb71dbb')}}</span>(<span>{{$t('i18nn_5734a059a4622f20')}}</span>30<span>{{$t('i18nn_3258bbe488af3e79')}}</span>)
					</el-button>
				</el-button-group>
			</div>
			<div>
				<el-link type="primary" @click="toMoreList()">
					<span>{{$t('i18nn_c3525eb110db58aa')}}</span>...
				</el-link>
			</div>

		</div>
		<div style="width: 100%;" v-loading="loading_load">

			<!-- <el-tabs v-model="activeName" style="width: 100%;">
				<el-tab-pane label="按月(前12个月)" name="first" style="width: 100%;">
					<div style="display: flex; width: 100%;">
						<ChartsSmallOrder :openTime="ChartsMonDataOpenTime" :chartData="{
									title: $t('684a2afb069b6016'),
									x_name: $t('i18nn_9954ce6437e66f41'),
									y_name: $t('684a2afb069b6016'),
									XData: ChartDataMon.XData,
									YData: ChartDataMon.YData1,
								}"></ChartsSmallOrder>
						<ChartsSmallOrder :openTime="ChartsMonDataOpenTime" :chartData="{
									title: $t('i18nn_6373cb1d1204d580'),
									x_name: $t('i18nn_9954ce6437e66f41'),
									y_name: $t('i18nn_6373cb1d1204d580'),
									XData: ChartDataMon.XData,
									YData: ChartDataMon.YData2,
								}"></ChartsSmallOrder>
					</div>
				</el-tab-pane>
				<el-tab-pane label="按日(前30天)" name="second" style="width: 100%;">
					<div style="display: flex; width: 100%;">
						<ChartsSmallOrder :openTime="ChartsDayDataOpenTime" :chartData="{
									title: $t('684a2afb069b6016'),
									x_name: $t('i18nn_ac2c5e612fb71dbb'),
									y_name: $t('684a2afb069b6016'),
									XData: ChartDataDay.XData,
									YData: ChartDataDay.YData1,
								}"></ChartsSmallOrder>
						<ChartsSmallOrder :openTime="ChartsDayDataOpenTime" :chartData="{
									title: $t('i18nn_6373cb1d1204d580'),
									x_name: $t('i18nn_ac2c5e612fb71dbb'),
									y_name: $t('i18nn_6373cb1d1204d580'),
									XData: ChartDataDay.XData,
									YData: ChartDataDay.YData2,
								}"></ChartsSmallOrder>
					</div>
				</el-tab-pane>
			</el-tabs> -->

			<el-carousel height="220px" ref="carousel" :autoplay="true" :interval="10000" direction="vertical" @change="changeCarousel">
				<el-carousel-item>
					<div style="display: flex;">
						<ChartsSmallOrder :openTime="ChartsMonDataOpenTime" :chartData="{
			    				title: $t('684a2afb069b6016'),
			    				x_name: $t('i18nn_9954ce6437e66f41'),
			    				y_name: $t('684a2afb069b6016'),
			    				XData: ChartDataMon.XData,
			    				YData: ChartDataMon.YData1,
			    			}"></ChartsSmallOrder>
						<ChartsSmallOrder :openTime="ChartsMonDataOpenTime" :chartData="{
			    				title: $t('i18nn_6373cb1d1204d580'),
			    				x_name: $t('i18nn_9954ce6437e66f41'),
			    				y_name: $t('i18nn_6373cb1d1204d580'),
			    				XData: ChartDataMon.XData,
			    				YData: ChartDataMon.YData2,
			    			}"></ChartsSmallOrder>
					</div>
				</el-carousel-item>
				<el-carousel-item>
					<div style="display: flex;">
						<ChartsSmallOrder :openTime="ChartsDayDataOpenTime" :chartData="{
									title: $t('684a2afb069b6016'),
									x_name: $t('i18nn_ac2c5e612fb71dbb'),
									y_name: $t('684a2afb069b6016'),
									XData: ChartDataDay.XData,
									YData: ChartDataDay.YData1,
								}"></ChartsSmallOrder>
						<ChartsSmallOrder :openTime="ChartsDayDataOpenTime" :chartData="{
									title: $t('i18nn_6373cb1d1204d580'),
									x_name: $t('i18nn_ac2c5e612fb71dbb'),
									y_name: $t('i18nn_6373cb1d1204d580'),
									XData: ChartDataDay.XData,
									YData: ChartDataDay.YData2,
								}"></ChartsSmallOrder>
					</div>
				</el-carousel-item>
			</el-carousel>

			<!-- <el-card style="flex:1;">
				<div slot="header" style="display: flex;justify-content: space-between;">
					<div style="font-size: 16px;">
						<i class="el-icon-data-line"></i>
						<span>{{$t('i18nn_37181c74559db182')}}</span>
						<el-tag type="primary">{{$t('i18nn_9954ce6437e66f41')}}</el-tag>
					</div>
					<div>
						
					</div>
					
				</div>
				<div style="display: flex;">
					<ChartsSmallOrder :openTime="ChartsMonDataOpenTime" :chartData="{
								title: $t('684a2afb069b6016'),
								x_name: $t('i18nn_9954ce6437e66f41'),
								y_name: $t('684a2afb069b6016'),
								XData: ChartDataMon.XData,
								YData: ChartDataMon.YData1,
							}"></ChartsSmallOrder>
					<ChartsSmallOrder :openTime="ChartsMonDataOpenTime" :chartData="{
								title: $t('i18nn_6373cb1d1204d580'),
								x_name: $t('i18nn_9954ce6437e66f41'),
								y_name: $t('i18nn_6373cb1d1204d580'),
								XData: ChartDataMon.XData,
								YData: ChartDataMon.YData2,
							}"></ChartsSmallOrder>
				</div>
			</el-card>
			<el-card style="flex:1;">
				<div slot="header" style="display: flex;justify-content: space-between;">
					<div style="font-size: 20px;">
						<i class="el-icon-data-line"></i>
						<span>{{$t('i18nn_37181c74559db182')}}</span>
						<el-tag type="success">{{$t('i18nn_ac2c5e612fb71dbb')}}</el-tag>
					</div>
					<div>
						
					</div>
					
				</div>
				<div style="display: flex;">
					<ChartsSmallOrder :openTime="ChartsDayDataOpenTime" :chartData="{
								title: $t('684a2afb069b6016'),
								x_name: $t('i18nn_ac2c5e612fb71dbb'),
								y_name: $t('684a2afb069b6016'),
								XData: ChartDataDay.XData,
								YData: ChartDataDay.YData1,
							}"></ChartsSmallOrder>
					<ChartsSmallOrder :openTime="ChartsDayDataOpenTime" :chartData="{
								title: $t('i18nn_6373cb1d1204d580'),
								x_name: $t('i18nn_ac2c5e612fb71dbb'),
								y_name: $t('i18nn_6373cb1d1204d580'),
								XData: ChartDataDay.XData,
								YData: ChartDataDay.YData2,
							}"></ChartsSmallOrder>
				</div>
			</el-card> -->
		</div>
	</el-card>
</template>

<script>
	import ChartsSmallOrder from '@/components/WarehouseCenter2/Home/components/ChartsSmallOrder.vue';
	export default {
		components: {
			ChartsSmallOrder
		},
		data() {
			return {
				loading_load: false,

				CarouselIndex: 0,
				
				activeName:'first',

				ChartsMonDataOpenTime: '',
				// ChartsMonDataParm: {},
				ChartDataMon: {},

				ChartsDayDataOpenTime: '',
				// ChartsDayDataParm: {},
				ChartDataDay: {},
				//查询，排序方式
				// filterData: {
				// 	orderBy: 'id_', //排序字段
				// 	sortAsc: 'desc', //desc降序，asc升序

				// 	// agentUser: '',
				// 	// "accountPeriod":"",
				// 	daterange: [],
				// 	// goodsSku: '',
				// 	// whNo: '',
				// 	// userId: ''
				// 	// goodsSku: '',
				// 	// goodsName:""
				// }
			};
		},
		activated() {
			this.initData();
		},
		mounted() {},
		methods: {
			initData() {
				this.getPageData();
				this.getPageData2();
			},

			changeCarousel(index) {
				console.log('changeCarousel', index);
				this.CarouselIndex = index;
			},
			// toPageName(event, name, query) {
			// 	event.stopPropagation();
			// 	this.$router.push({
			// 		name: name,
			// 		query: query
			// 	});
			// },
			toMoreList() {
				// event.stopPropagation();
				this.$router.push({
					name: "OrderStatisticsByMon"
				})
			},

			initChar(data, data2) {
				this.ChartsMonDataParm = {};
				this.ChartsMonDataOpenTime = new Date().getTime();
				let XData = [],
					YData1 = [],
					YData2 = [];
				data.forEach(item => {
					XData.push(item.commitDate);
					YData1.push(item.totalCount);
				});
				data2.forEach(item => {
					YData2.push(item.totalCount);
				});
				this.ChartDataMon = {
					// title: this.$t('i18nn_52b4f481aab3e343'),
					// type: '1',
					XData: XData,
					YData1: YData1,
					YData2: YData2
				}
			},
			initChar2(data, data2, title) {
				this.ChartsDayDataParm = {};
				this.ChartsDayDataOpenTime = new Date().getTime();
				let XData = [],
					YData1 = [],
					YData2 = [];
				data.forEach(item => {
					XData.push(item.commitDate);
					YData1.push(item.dayCount);
				});
				data2.forEach(item => {
					YData2.push(item.dayCount);
				});
				this.ChartDataDay = {
					// title: this.$t('i18nn_6e5d728ebf9703a8') + title,
					XData: XData,
					YData1: YData1,
					YData2: YData2
				}
			},
			
			setActiveItem(index){
				this.$nextTick(()=>{
					this.$refs.carousel.setActiveItem(index);
				})
			},

			//分页的筛选项数据
			// pageFilterData() {
			// 	console.log('daterange', this.filterData.daterange);
			// 	let startTime = '';
			// 	let endTime = '';
			// 	if (this.filterData.daterange && this.filterData.daterange.length >= 2) {
			// 		startTime = this.filterData.daterange[0];
			// 		endTime = this.filterData.daterange[1];
			// 	} else {
			// 		// this.$message.warning(this.$t('i18nn_cffd7c9783c11047'));
			// 	}
			// 	// startD endD
			// 	return {
			// 		// userId: this.filterData.userId ? this.filterData.userId : null,
			// 		// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
			// 		startD: startTime ? startTime : null,
			// 		endD: endTime ? endTime : null,
			// 		// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
			// 		// whNo: this.filterData.whNo ? this.filterData.whNo : null
			// 	};
			// },
			//请求分页数据
			getPageData() {
				// let _this = this;

				// let filterData = Object.assign({
				// 	offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
				// 	limit: this.pagination.page_size, //当前页显示数目
				// },this.pageFilterData())

				//前面12个月，365天
				let filterData = {
					endD: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime()+3600 * 1000 * 24),
					startD: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 365 * 24 * 60 * 60 * 1000)
				};
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhOrderStatisticsByMon, filterData)
					.then(({
						data
					}) => {
						
						this.loading_load = false;
						if (200 == data.code && data.data) {
							//表格显示数据
							// this.tableData = data.data;
							let charData = data.data;
							charData.SDS = charData.SDS ? charData.SDS : [];
							charData.STF = charData.STF ? charData.STF : [];

							this.initChar(charData.SDS, charData.STF);

						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},
			//请求分页数据
			getPageData2() {
				// let _this = this;

				// let filterData = Object.assign({
				// 	offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
				// 	limit: this.pagination.page_size, //当前页显示数目
				// },this.pageFilterData())

				// let filterData = this.pageFilterData();

				//前30天
				let filterData = {
					endD: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime()+3600 * 1000 * 24),
					startD: this.$Utils.fomatterDate_YYYYMMdd(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
				};

				//显示选择数据最后月的当月数据(1号至当月最后一号)
				// let titleMon = "";
				//点击的值
				// if (dateMon) {
				// 	filterData.endD = dateMon;
				// }
				//筛选传入的，最后月的值
				// if (filterData.endD) {
				// 	try {
				// 		let newDataSec = this.$Utils.getMonByDaySection(filterData.endD);
				// 		console.log("newDataSec", newDataSec);
				// 		filterData.startD = newDataSec[0] ? newDataSec[0] : null;
				// 		filterData.endD = newDataSec[1] ? newDataSec[1] : null;
				// 		titleMon = "(" + newDataSec[2] + ")";
				// 	} catch (e) {
				// 		console.log(e)
				// 		//TODO handle the exception
				// 	}
				// }

				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhOrderStatisticsByDay, filterData)
					.then(({
						data
					}) => {
						
						this.loading_load = false;
						if (200 == data.code && data.data) {
							//表格显示数据
							// this.tableData = data.data;
							let charData = data.data;
							charData.SDS = charData.SDS ? charData.SDS : [];
							charData.STF = charData.STF ? charData.STF : [];

							this.initChar2(charData.SDS, charData.STF, '');

						} else {
							this.$message.warning(data.msg ? data.msg : this.$t('hytxs0000029'));
						}
					})
					.catch(error => {
						console.log(error);
						this.$message.error(this.$t('hytxs0000030'));
						this.loading_load = false;
					});
			},
		}
	};
</script>

<style scoped lang="less">
	// @import url(../../../assets/css/home.less);
</style>
